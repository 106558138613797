import { useDispatch } from 'react-redux'
import type { Dispatch } from 'redux'

import type { Action, NavMenuState } from 'common/reducers/navMenu'

type AppDispatch = Dispatch<Action, { navMenu: NavMenuState }>

const useAppDispatch: () => AppDispatch = useDispatch

export default useAppDispatch
