import type { ReactNode } from 'react'
import MenuIcon from '@material-ui/icons/Menu'
import { Divider, SwipeableDrawer } from '@material-ui/core'

import isIOS from 'common/lib/isIOS'
import NavbarIconButton from 'common/components/navbar/NavbarIconButton'
import { navMenuStyle } from 'common/components/styles/navmenu'
import BaseMenuLoadingState from '../BaseMenuLoadingState'
import useStyles from './styles'

const SWIPEABLE_AREA_WIDTH = 20

type ProductLinks = {
  [key: string]: string
}

type Props = {
  footer?: ReactNode
  iconStyle?: { color?: string }
  isOpen: boolean
  navItems?: ReactNode
  onClose: () => void
  onOpen: () => void
  productLinks?: ReactNode & { props?: { productLinks: ProductLinks } }
  userSummary?: ReactNode
}

const UnconnectedBaseNavMenu = ({
  isOpen,
  iconStyle,
  onOpen,
  onClose,
  footer,
  productLinks,
  userSummary,
}: Props): JSX.Element => {
  const classes = useStyles()
  const drawerSwipeableArea = isIOS() ? SWIPEABLE_AREA_WIDTH : 0

  const isLoading = productLinks?.props?.productLinks === undefined

  return (
    <>
      <div className={classes.buttonWrapper}>
        <NavbarIconButton
          buttonClasses={{
            root: classes.button,
          }}
          data-testid="nav-drawer-button"
          htmlColor={iconStyle?.color || '#212121'}
          Icon={MenuIcon}
          onClick={onOpen}
        />
      </div>
      <SwipeableDrawer
        anchor="right"
        disableBackdropTransition={!isIOS()}
        disableDiscovery={isIOS()}
        disableSwipeToOpen={false}
        onClose={onClose}
        onOpen={onOpen}
        open={isOpen}
        SwipeAreaProps={{
          style: { minWidth: drawerSwipeableArea, height: 'unset' },
        }}
        swipeAreaWidth={drawerSwipeableArea}
      >
        <div data-testid="nav-drawer" style={navMenuStyle.container}>
          <div>
            {userSummary}
            {productLinks && <Divider />}
            {isLoading ? <BaseMenuLoadingState /> : productLinks}
          </div>
          <div>
            <Divider />
            {footer}
          </div>
        </div>
      </SwipeableDrawer>
    </>
  )
}

export default UnconnectedBaseNavMenu
