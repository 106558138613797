import type { ReactNode } from 'react'
import {
  Map as VisualDirectoryIcon,
  CompareArrows as MoveManagerIcon,
  CallSplit as ScenariosIcon,
  Language as PlanningPlatformIcon,
  List as MovesIcon,
  LocationCity as FacilitiesIcon,
  Settings as SettingsIcon,
  Timeline as ReportsIcon,
  Security as AdminIcon,
  AssignmentTurnedIn as RequestManagerIcon,
  PersonPinCircle as BookingManagerIcon,
  VerifiedUser as SafeguardIcon,
} from '@material-ui/icons'
import ForecastIcon from 'common/components/icons/Forecast'
import StackPlanIcon from 'common/components/icons/StackPlanIcon'
import LightbulbIcon from 'common/components/icons/LightbulbIcon'
import Products from '../../../constants/products'
import type { generalProps, commonProps } from './types'
import ProductItems from './ProductItems'
import FeedbackMenuItem from './FeedbackMenuItem'
import HelpMenuItem from './HelpMenuItem'
import VisitorManagementMenuItem from './VisitorManagementMenuItem'

const ProductMenuItems = ({ submenus }: generalProps): ReactNode => ({
  [Products.VISUAL_DIRECTORY]: ({ isGuest, props, selected }: commonProps) =>
    ProductItems({
      Icon: VisualDirectoryIcon,
      product: Products.VISUAL_DIRECTORY,
      submenu: undefined,
      link: props,
      isGuest,
      selected,
    }),
  [Products.BOOKING_MANAGER]: ({ isGuest, props, selected }: commonProps) =>
    ProductItems({
      Icon: BookingManagerIcon,
      product: Products.BOOKING_MANAGER,
      submenu: submenus ? submenus[Products.BOOKING_MANAGER] : undefined,
      link: props,
      isGuest,
      selected,
    }),
  [Products.MOVE_MANAGER]: ({ isGuest, props, selected }: commonProps) =>
    ProductItems({
      Icon: MoveManagerIcon,
      product: Products.MOVE_MANAGER,
      submenu: undefined,
      link: props,
      isGuest,
      selected,
    }),
  [Products.MOVE_MANAGER_BETA]: ({ isGuest, props, selected }: commonProps) =>
    ProductItems({
      Icon: PlanningPlatformIcon,
      product: Products.PLANNING_PLATFORM,
      submenu: submenus ? submenus[Products.PLANNING_PLATFORM] : undefined,
      link: props,
      isGuest,
      selected,
    }),
  [Products.MOVES]: ({ isGuest, props, selected }: commonProps) =>
    ProductItems({
      Icon: MovesIcon,
      product: Products.MOVES,
      submenu: undefined,
      link: props,
      isGuest,
      selected,
    }),
  [Products.SCENARIOS]: ({ isGuest, props, selected }: commonProps) =>
    ProductItems({
      Icon: ScenariosIcon,
      product: Products.SCENARIOS,
      submenu: undefined,
      link: props,
      isGuest,
      selected,
    }),
  [Products.GROWTH_FORECAST]: ({ isGuest, props, selected }: commonProps) =>
    ProductItems({
      Icon: ForecastIcon,
      product: Products.GROWTH_FORECAST,
      submenu: undefined,
      link: props,
      isGuest,
      selected,
    }),
  [Products.STACK_PLANS]: ({ isGuest, props, selected }: commonProps) =>
    ProductItems({
      Icon: StackPlanIcon,
      product: Products.STACK_PLANS,
      submenu: undefined,
      link: props,
      isGuest,
      selected,
    }),
  [Products.PREFERENCES]: ({ isGuest, props, selected }: commonProps) =>
    ProductItems({
      Icon: SettingsIcon,
      product: Products.PREFERENCES,
      submenu: undefined,
      link: props,
      isGuest,
      selected,
    }),
  [Products.REPORTS]: ({ isGuest, props, selected }: commonProps) =>
    ProductItems({
      Icon: ReportsIcon,
      product: Products.REPORTS,
      submenu: undefined,
      link: props,
      isGuest,
      selected,
    }),
  [Products.REPORTS_BETA]: ({ isGuest, props, selected }: commonProps) =>
    ProductItems({
      Icon: ReportsIcon,
      product: Products.REPORTS_BETA,
      submenu: undefined,
      link: props,
      isGuest,
      selected,
    }),
  [Products.INSIGHTS_HUB]: ({ isGuest, props, selected }: commonProps) =>
    ProductItems({
      Icon: ReportsIcon,
      product: Products.INSIGHTS_HUB,
      submenu: undefined,
      link: props,
      isGuest,
      selected,
    }),
  [Products.FACILITIES]: ({ isGuest, props, selected }: commonProps) =>
    ProductItems({
      Icon: FacilitiesIcon,
      product: Products.FACILITIES,
      submenu: undefined,
      link: props,
      isGuest,
      selected,
    }),
  [Products.ADMIN]: ({ isGuest, props, selected }: commonProps) =>
    ProductItems({
      Icon: AdminIcon,
      product: Products.ADMIN,
      submenu: submenus ? submenus[Products.ADMIN] : undefined,
      link: props,
      isGuest,
      selected,
    }),
  [Products.REQUEST_MANAGER]: ({ isGuest, props, selected }: commonProps) =>
    ProductItems({
      Icon: RequestManagerIcon,
      product: Products.REQUEST_MANAGER,
      submenu: undefined,
      link: props,
      isGuest,
      selected,
    }),
  [Products.SAFEGUARD]: ({ isGuest, props, selected }: commonProps) =>
    ProductItems({
      Icon: SafeguardIcon,
      product: Products.SAFEGUARD,
      submenu: undefined,
      link: props,
      isGuest,
      selected,
    }),
  [Products.SUBMIT_FEATURE_IDEA]: ({ isGuest, props, selected }: commonProps) =>
    ProductItems({
      Icon: LightbulbIcon,
      product: Products.SUBMIT_FEATURE_IDEA,
      submenu: undefined,
      link: props,
      isGuest,
      selected,
    }),
  [Products.VISITOR_MANAGEMENT]: VisitorManagementMenuItem,
  [Products.SEND_FEEDBACK]: FeedbackMenuItem,
  [Products.HELP]: HelpMenuItem,
  [Products.WORKPLACE_PORTAL]: HelpMenuItem,
})

export default ProductMenuItems
