export default {
  Floor: {
    CENTER_PIN: 'RENDERED_FLOOR/CENTER_PIN',
    CENTER_TO_REQUESTED: 'RENDERED_FLOOR/CENTER_TO_REQUESTED',
    CENTER_TO_ENDED: 'RENDERED_FLOOR/CENTER_TO_ENDED',
    CLEAR_SHOULD_CENTER: 'CLEAR_SHOULD_CENTER',
    DOUBLE_CLICK: 'RENDERED_FLOOR/DOUBLE_CLICK',
    FILTER_FOCUSED: 'FLOOR_FILTER/FOCUSED',
    FILTER_LEAVE_DOWN: 'FLOOR_FILTER/LEAVE_DOWN_REQUESTED',
    LIST_LEAVE: 'FLOOR_LIST/LEAVE_REQUESTED',
    LIST_LEAVE_UP: 'FLOOR_LIST/LEAVE_UP_REQUESTED',
    LOCATE_RESOURCE_REQUESTED: 'RENDERED_FLOOR/LOCATE_RESOURCE_REQUESTED',
    PINCH: 'RENDERED_FLOOR/PINCH',
    PAN: 'RENDERED_FLOOR/PAN',
    RECEIVE: 'RECEIVE_FLOORS',
    RECEIVE_EXPECTED_FLOOR_PLAN_URL: 'RECEIVE_EXPECTED_FLOOR_PLAN_URL',
    FLOOR_CLEARED: 'FLOOR_CLEARED',
    RECEIVE_SELECTED: 'RECEIVE_SELECTED_FLOOR',
    REQUEST_UPDATE_FAVORITE_SITE: 'REQUEST_UPDATE_FAVORITE_SITE',
    RESET: 'RESET_FLOOR',
    SELECT_FLOOR: 'SELECT_FLOOR',
    SELECTOR_ZOOM_ALMOST_ENDED: 'RENDERED_FLOOR/SELECTOR_ZOOM_ALMOST_ENDED',
    SELECTOR_ZOOM_ENDED: 'RENDERED_FLOOR/SELECTOR_ZOOM_ENDED',
    SELECTOR_ZOOM_REQUESTED: 'RENDERED_FLOOR/SELECTOR_ZOOM_REQUESTED',
    SELECTOR_ZOOM_STARTED: 'RENDERED_FLOOR/SELECTOR_ZOOM_STARTED',
    BACKGROUND_READY: 'BACKGROUND_READY',
    SET_ICON_SCALE: 'RENDERED_FLOOR/SET_ICON_SCALE',
    SET_SELECTED_FLOOR_RENDER_STATUS: 'SET_SELECTED_FLOOR_RENDER_STATUS',
    SET_SHOULD_CENTER: 'SET_SHOULD_CENTER',
    SET_STAGE_DIMENSIONS_ARE_STABLE: 'SET_STAGE_DIMENSIONS_ARE_STABLE',
    SET_ROOM_STATUS_TIMER: 'SET_ROOM_STATUS_TIMER',
    STAGE_DIMENSIONS_CHANGED: 'RENDERED_FLOOR/STAGE_DIMENSIONS_CHANGED',
    STAGE_DIMENSIONS_CHANGED_PRESERVING_VIEW:
      'RENDERED_FLOOR/STAGE_DIMENSIONS_CHANGED_PRESERVING_VIEW',
    WHEEL: 'RENDERED_FLOOR/WHEEL',
    ZOOM_IN_BUTTON_CLICKED: 'RENDERED_FLOOR/ZOOM_IN_BUTTON_CLICKED',
    ZOOM_OUT_BUTTON_CLICKED: 'RENDERED_FLOOR/ZOOM_OUT_BUTTON_CLICKED',
    private: {
      DOUBLE_CLICK_TWEEN_STEP: 'PRIVATE/RENDERED_FLOOR/DOUBLE_CLICK_TWEEN_STEP',
      LOCATE_RESOURCE_TWEEN_STEP:
        'PRIVATE/RENDERED_FLOOR/LOCATE_RESOURCE_TWEEN_STEP',
      REVEAL_RESOURCE_TWEEN_STEP:
        'PRIVATE/RENDERED_FLOOR/REVEAL_RESOURCE_TWEEN_STEP',
      BOX_FOCUS_TWEEN_STEP: 'PRIVATE/RENDERED_FLOOR/BOX_FOCUS_TWEEN_STEP',
      SNAP_BACK_TWEEN_STEP: 'PRIVATE/RENDERED_FLOOR/SNAP_BACK_TWEEN_STEP',
      STAGE_LEFT_CHANGED_TWEEN_STEP:
        'PRIVATE/RENDERED_FLOOR/STAGE_LEFT_CHANGED_TWEEN_STEP',
      WHEEL_TWEEN_STEP: 'PRIVATE/RENDERED_FLOOR/WHEEL_TWEEN_STEP',
      ZOOM_BUTTON_TWEEN_STEP: 'PRIVATE/RENDERED_FLOOR/ZOOM_BUTTON_TWEEN_STEP',
    },
  },
  Fonts: {
    RECEIVE: 'RECEIVE_FONT',
  },
  NavMenu: {
    CLOSE_REQUESTED: 'NAVIGATION_MENU/CLOSE_REQUESTED',
    OPEN_REQUESTED: 'NAVIGATION_MENU/OPEN_REQUESTED',
  },
  Notification: {
    ACKNOWLEDGE: 'ACKNOWLEDGE_NOTIFICATION',
    ACKNOWLEDGE_TYPE: 'ACKNOWLEDGE_NOTIFICATION_TYPE',
    ADD: 'ADD_NOTIFICATION',
  },
  Print: {
    Header: {
      RECEIVE: 'RECEIVE_PRINT_HEADER',
    },
    Legend: {
      RECEIVE: 'RECEIVE_PRINT_LEGEND',
      RECEIVE_DEFAULTS: 'RECEIVE_PRINT_LEGEND_DEFAULTS',
      UPDATE_COORDINATES: 'UPDATE_PRINT_LEGEND_COORDINATES',
    },
    private: {
      PADDING_SIZE_TWEEN_STEP: 'PRINT/PRIVATE/PADDING_SIZE_TWEEN_STEP',
      PREVIEW_WINDOW_TWEEN_STEP: 'PRINT/PRIVATE/PREVIEW_WINDOW_TWEEN_STEP',
    },
    SelectedSettings: {
      UPDATE: 'UPDATE_PRINT_SELECTED_SETTING',
    },
    MOUNT_RENDERED_FLOOR: 'PRINT/MOUNT_RENDERED_FLOOR',
    RECEIVE_PREVIEW_WINDOW_DIMENSIONS:
      'PRINT/RECEIVE_PREVIEW_WINDOW_DIMENSIONS',
    SET_PADDING_SIZE: 'PRINT/SET_PADDING_SIZE',
    END_PRINT: 'END_PRINT',
  },
  Room: {
    INIT: 'INIT_ROOM',
  },
  Seat: {
    INIT: 'INIT_SEAT',
    ADD_TO_PREVIOUSLY_BOOKED_SEATS: 'ADD_TO_PREVIOUSLY_BOOKED_SEATS',
    RECEIVE_SEAT_INBOUND_MOVE: 'RECEIVE_SEAT_INBOUND_MOVE',
    REMOVE: 'REMOVE_SEAT',
  },
  Settings: {
    RECEIVE: 'RECEIVE_SETTINGS',
  },
  Snackbar: {
    SHOW: 'SHOW_SNACKBAR',
    CLOSE: 'CLOSE_SNACKBAR',
  } as const,
  Stage: {
    COMPONENT_IDLED: 'STAGE/COMPONENT_IDLED',
    COMPONENT_RESUMED: 'STAGE/COMPONENT_RESUMED',
  },
  User: {
    RECEIVE: 'RECEIVE_USER',
  },
} as const
