// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import endsWith from 'lodash/endsWith'
import merge from 'lodash/merge'
import type defaultFlags from 'common/launchDarkly/default_flags'

const checkDevelopmentHostname = () =>
  window.location.hostname === '127.0.0.1' ||
  window.location.hostname === 'localhost' ||
  endsWith(window.location.hostname, 'ossd.co')

type User = {
  key: string
  anonymous: boolean
  custom: {
    hostname: string
    pendoVisitorReports: Array<string>
  }
}

declare global {
  interface OSS {
    launchDarklyUser?: User
    image_url: (args: string) => string
    VD_URL?: string
  }

  interface Window {
    oss: OSS
  }
}

const launchDarklyContext = () => {
  const fallbackUser = {
    custom: { hostname: undefined },
    key: undefined,
    anonymous: true,
  }

  const userFromBackendConfig = window.oss?.launchDarklyUser || fallbackUser

  const { custom: customAttributes, ...userWithoutCustomAttributes } =
    userFromBackendConfig

  const frontendHostname =
    process.env.NODE_ENV === 'development'
      ? 'localhost'
      : window.location.hostname

  return merge(userWithoutCustomAttributes, {
    ...customAttributes,
    hostname: frontendHostname,
    kind: 'user',
  })
}

type ConfigOptions<F> = {
  flags?: F
}

const config = <F extends Partial<typeof defaultFlags>>({
  flags,
}: ConfigOptions<F> = {}) => ({
  clientSideID: (() => {
    if (Object.keys(window?.oss?.mockedFlags || {}).length > 0) {
      return ''
    }
    if (process.env.NODE_ENV === 'production' && !checkDevelopmentHostname()) {
      return '608027c7bcf4820be9b164d0'
    }

    return '608027c7bcf4820be9b164cf'
  })(),
  context: launchDarklyContext(),
  flags,
  reactOptions: {
    useCamelCaseFlagKeys: false,
  },
})

export default config
