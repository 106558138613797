import { lazy } from 'react'
import type { ReactNode } from 'react'
import type { Submenu } from 'common/types/submenu'
import SimpleProductItem from './SimpleProductItem'
import SubMenuProductItem from './SubMenuProductItem'

import {
  isVisualDirectory,
  shouldHideVDSubmenu,
  isProductSelected,
} from '../lib'

interface Link {
  href?: string
  target?: string
}

interface Props {
  Icon: ReactNode
  product: string
  submenu?: Submenu
  link: Link
  isGuest?: boolean
  selected?: string
}

const VisualDirectoryProductItem = lazy(
  () =>
    import(
      /* webpackChunkName: "vd_product_submenu_item" */
      './VisualDirectoryProductItem'
    ),
)

const ProductItems = ({
  Icon,
  product,
  submenu,
  link,
  isGuest,
  selected,
}: Props): JSX.Element => {
  const { href } = link

  if (isVisualDirectory(product) && !shouldHideVDSubmenu(product, selected)) {
    return (
      <VisualDirectoryProductItem
        key="visual-directory-submenu-item"
        Icon={Icon}
        isGuest={isGuest}
        isOpen
        name={product}
      />
    )
  }

  if (isVisualDirectory(product) && shouldHideVDSubmenu(product, selected)) {
    return (
      <SimpleProductItem
        key="visual-directory-single-item"
        Icon={Icon}
        isProductRegistered={isVisualDirectory(product)}
        isProductSelected={isProductSelected(product, selected, href)}
        link={link}
        product={product}
      />
    )
  }

  if (submenu) {
    return (
      <SubMenuProductItem
        key={`${product}-item-with-submenu`}
        Icon={Icon}
        name={product}
        submenu={submenu}
      />
    )
  }

  return (
    <SimpleProductItem
      key={`${product}-item-single-item`}
      Icon={Icon}
      isProductRegistered={isVisualDirectory(product)}
      isProductSelected={isProductSelected(product, selected, href)}
      link={link}
      product={product}
    />
  )
}

export default ProductItems
