import { useTranslation, Trans } from 'react-i18next'
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

import NavMenuItem from 'common/components/navmenu/NavMenuItem'
import SmartPhoneIcon from 'common/icons/SmartPhoneIcon'

import QRCode from './QRCode'
import {
  actionsStyles,
  dialogStyles,
  titleStyles,
  contentStyles,
} from './styles'
import { IOS_APP_LINK_ITUNES, ANDROID_APP_LINK_GOOGLE_PLAY } from './constants'

const useActionsStyles = makeStyles(actionsStyles)
const useDialogStyles = makeStyles(dialogStyles)
const useTitleStyles = makeStyles(titleStyles)
const useContentStyles = makeStyles(contentStyles)

const MobileAppDialog = ({
  customIOSAppLink,
  isIOSAppEnabled,
  isUserAnonymous,
  isOpen,
  setIsOpen,
}: {
  customIOSAppLink: string | null | undefined
  isIOSAppEnabled?: boolean | null
  isUserAnonymous: boolean | null | undefined
  isOpen: boolean
  setIsOpen: (value: boolean) => void
}): JSX.Element => {
  const { t } = useTranslation('common', {
    keyPrefix: 'common',
  })

  const actionsClasses = useActionsStyles()
  const dialogClasses = useDialogStyles()
  const titleClasses = useTitleStyles()
  const contentClasses = useContentStyles()

  const handleIOSAppClick = () => setIsOpen(true)

  const handleIOSAppModalClose = () => setIsOpen(false)

  const isAppMobileVisible = isIOSAppEnabled && !isUserAnonymous

  return (
    <>
      {isAppMobileVisible && (
        <NavMenuItem
          component="a"
          isBold
          LeftIcon={SmartPhoneIcon}
          onClick={handleIOSAppClick}
        >
          {t('home_menu.mobile_app')}
        </NavMenuItem>
      )}

      <Dialog
        classes={dialogClasses}
        onClose={handleIOSAppModalClose}
        open={isOpen}
      >
        <DialogTitle classes={titleClasses}>
          {t('home_menu.mobile_app_dialog.title')}
        </DialogTitle>
        <DialogContent classes={contentClasses}>
          <DialogContentText classes={contentClasses}>
            <Trans
              components={{
                'app-store-link': (
                  <a
                    href={customIOSAppLink || IOS_APP_LINK_ITUNES}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    App Store
                  </a>
                ),
                'google-play-link': (
                  <a
                    href={ANDROID_APP_LINK_GOOGLE_PLAY}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Google Play
                  </a>
                ),
              }}
              i18nKey="common.home_menu.mobile_app_dialog.instructions.first_step"
            />
          </DialogContentText>
          <DialogContentText classes={contentClasses}>
            {t('home_menu.mobile_app_dialog.instructions.second_step')}
          </DialogContentText>
          <DialogContentText classes={contentClasses}>
            {t('home_menu.mobile_app_dialog.instructions.third_step')}
          </DialogContentText>
          <QRCode />
        </DialogContent>
        <DialogActions classes={actionsClasses}>
          <Button color="primary" onClick={handleIOSAppModalClose}>
            {t('actions.cancel')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default MobileAppDialog
