// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
const UNAUTHORIZED_STATUS = 401
/* @ts-expect-error auto-src: noflow */
const originalFetch = window.originalFetch || window.fetch

/* @ts-expect-error auto-src: noflow */
const authenticatedFetch = (...params) => {
  if (window.oss?.testUtils?.requestsBlocked) {
    return new Promise((resolve) =>
      resolve(
        new Response('', {
          status: 503,
        }),
      ),
    )
  }

  if (window.oss?.testUtils) {
    window.oss.testUtils.pendingRequestsCount += 1
  }

  /* @ts-expect-error auto-src: noflow */
  return originalFetch(...params).then((response) => {
    if (window.oss?.testUtils) {
      window.oss.testUtils.pendingRequestsCount -= 1
    }

    if (response.status === UNAUTHORIZED_STATUS) {
      /* @ts-expect-error Typescript upgrade to 4.7 */
      window.location.reload(true)
    }

    return response
  })
}

const withAuthenticatedFetch = () => {
  /* @ts-expect-error auto-src: noflow */
  window.originalFetch = originalFetch
  window.fetch = authenticatedFetch
}

export default withAuthenticatedFetch
export { originalFetch, authenticatedFetch }
